import React, { FunctionComponent, ReactElement } from 'react';
import Hero from '../Hero/Hero';
import HorizontalEventCard from './EventCard/HorizontalEventCard';
import VerticalEventCard from './EventCard/VerticalEventCard';
import EventsBanner from '../../assets/images/Events/EventsBanner.png';
import PhotoGallery from './PhotoGallery/PhotoGallery';
import InternalOrExternalLink from '../InternalOrExternalLink/InternalOrExternalLink';
import { useEvents } from '../../common/CustomHooks/UseEvents';

const EVENTS_PAGE_PADDING_Y = 'xl:pt-8 lg:pt-8 md:pt-8 sm:pt-4 xs:pt-4 xl:pb-20 lg:pb-20 md:pb-20 sm:pb-8 xs:pb-8';
const HORIZONTAL_CARD = 'xl:flex lg:flex md:hidden sm:hidden xs:hidden';
const VERTICAL_CARD = 'xl:hidden lg:hidden md:block sm:block xs:block';
const BUTTON_STYLE = 'text-dark-mint uppercase';
const BUTTON_STYLE_DISABLED = 'text-disabled-light-grey uppercase';
const BUTTON_LABEL = 'view event page';

const EventsPage: FunctionComponent = (): ReactElement => {
  const { past, future, state } = useEvents();

  return (
    <div className="w-full">
      <Hero image={EventsBanner} title="Events" classString="bg-events" />
      <div className={`flex flex-col items-center ${EVENTS_PAGE_PADDING_Y}`}>
        {future.length === 0 && past.length === 0 && state === 'hasValue' && (
          <p className="text-white text-5xl font-oswald xl:mt-20 lg:mt-20 md:mt-20 sm:mt-8 xs:mt-8">
            No Events, Check back later
          </p>
        )}
        {state === 'new' && (
          <p className="text-white text-5xl font-oswald xl:mt-20 lg:mt-20 md:mt-20 sm:mt-8 xs:mt-8">...Loading</p>
        )}
        {future.length > 0 && (
          <p className="text-white text-5xl font-oswald xl:mt-20 lg:mt-20 md:mt-20 sm:mt-8 xs:mt-8">Upcoming Events</p>
        )}
        {future.map((e, i) => {
          const index = i;
          return (
            <div key={`${e.eventName}-upcomingEvent-${index}`}>
              <HorizontalEventCard {...e} classString={HORIZONTAL_CARD}>
                {e.link && <InternalOrExternalLink url={e.link} text={BUTTON_LABEL} classString={BUTTON_STYLE} />}
              </HorizontalEventCard>
              <VerticalEventCard {...e} classString={VERTICAL_CARD}>
                {e.link && <InternalOrExternalLink url={e.link} text={BUTTON_LABEL} classString={BUTTON_STYLE} />}
              </VerticalEventCard>
            </div>
          );
        })}
        {past.length > 0 && (
          <p className="text-white text-5xl font-oswald xl:mt-20 lg:mt-20 md:mt-20 sm:mt-8 xs:mt-8">Past Events</p>
        )}
        {past.map((e, i) => {
          const index = i;
          return (
            <div key={`${e.eventName}-pastEvent-${index}`}>
              <HorizontalEventCard {...e} disabled classString={HORIZONTAL_CARD}>
                {e.link && (
                  <InternalOrExternalLink url={e.link} text={BUTTON_LABEL} classString={BUTTON_STYLE_DISABLED} />
                )}
              </HorizontalEventCard>
              <VerticalEventCard {...e} disabled classString={VERTICAL_CARD}>
                {e.link && (
                  <InternalOrExternalLink url={e.link} text={BUTTON_LABEL} classString={BUTTON_STYLE_DISABLED} />
                )}
              </VerticalEventCard>
            </div>
          );
        })}
      </div>
      <div className="flex justify-center">
        <PhotoGallery />
      </div>
    </div>
  );
};

export default EventsPage;
