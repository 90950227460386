import React, { FunctionComponent, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import './InternalOrExternalLink.css';

export interface InternalOrExternalLinkProps {
  url: string;
  text: string;
  classString: string;
}

const InternalOrExternalLink: FunctionComponent<InternalOrExternalLinkProps> = (props): ReactElement => {
  return (
    <>
      {props.url.includes('http') ? (
        <a href={props.url} target="__blank__" className={`externalLink ${props.classString}`}>
          {props.text}
        </a>
      ) : (
        <Link to={props.url} className={`internalLink ${props.classString}`}>
          {props.text}
        </Link>
      )}
    </>
  );
};

export default InternalOrExternalLink;
