import React, { FunctionComponent, ReactElement, useRef, useState } from 'react';
import Tag from './Tag/Tag';
import { TagEnum } from '../../../common/enums/Tag.enum';
import LocationIcon from '../../../assets/icons/LocationIcon.svg';
import ClockIcon from '../../../assets/icons/ClockIcon.svg';
import CalendarIcon from '../../../assets/icons/CalendarIcon.svg';
import { Link } from 'react-router-dom';
import { PRIMARY_ROUTES } from '../../../common/Routes/PrimaryRoutes';
import { ValidEvent } from '../../../common/Interfaces/EventInfo';
import './EventCard.css';
import './HorizontalEventCard.css';
import { useWillOverflow } from '../../../common/CustomHooks/UseWillOverflow';
/* eslint-disable @typescript-eslint/no-explicit-any */

const BUTTON_STYLE = 'text-dark-mint uppercase';
const BUTTON_STYLE_DISABLED = 'text-disabled-light-grey uppercase';

export const ENABLED_DATEBOX = {
  text: 'text-dark-mint',
  border: 'bg-gradient-to-br from-dark-sherbert to-dark-mint',
  background: 'bg-dark-deepSea'
};

export const DISABLED_DATEBOX = {
  text: 'text-disabled-light-grey',
  border: 'bg-disabled-light-grey',
  background: 'bg-disabled-dark-grey'
};

export interface EventCardProps extends ValidEvent {
  disabled?: boolean;
  classString?: string;
}

const HorizontalEventCard: FunctionComponent<EventCardProps> = (props): ReactElement => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const descriptionRef = useRef();
  const willOverflow = useWillOverflow(descriptionRef);

  const { disabled } = props;

  const eventCardBackground = disabled ? 'bg-disabled-dark-grey' : 'bg-dark-deepSea';
  const dateBox = disabled ? DISABLED_DATEBOX : ENABLED_DATEBOX;

  const numDays = props.end.diff(props.start, 'day') + 1;
  const ONE_DAY_FORMAT = ['HHmm', 'HHmm'];
  const MULTI_DAY_FORMAT = ['MMM D', 'D, YYYY'];

  let DATE_FORMAT = [];
  if (numDays === 1) DATE_FORMAT = ONE_DAY_FORMAT;
  else DATE_FORMAT = MULTI_DAY_FORMAT;

  return (
    <div
      className={`horizontal-event-card-main ${props.classString} flex text-white ${eventCardBackground} justify-evenly p-8 mt-12`}
    >
      <div className={`flex flex-col ${expanded ? 'mt-8' : 'justify-center'} ${dateBox.text}`}>
        <div className={`horizontal-event-date-wrapper flex justify-center ${dateBox.border}`}>
          <div className={`flex justify-center ${dateBox.background} self-center`}>
            <p className="self-center text-5xl">{props.start.format('D')}</p>
          </div>
        </div>
        <p className="self-center text-2xl mt-1 uppercase">{props.start.format('MMM')}</p>
      </div>

      <div className="w-2/3">
        <div className="flex">
          <p className={`${expanded ? '' : 'truncate'} font-oswald text-2xl uppercase mr-8`}>{props.eventName}</p>
          {numDays > 1 && numDays <= 99 && (
            <Tag text={`${numDays} Days`} bgColor={disabled ? 'disabled-grey' : 'dark-teal'} bgOpacity="12" />
          )}
          {props.tags.includes(TagEnum.IN_PERSON) && (
            <Tag text={TagEnum.IN_PERSON} bgColor={disabled ? 'disabled-grey' : 'dark-lightGray'} bgOpacity="20" />
          )}
          {props.tags.includes(TagEnum.ONLINE) && (
            <Tag text={TagEnum.ONLINE} bgColor={disabled ? 'disabled-grey' : 'dark-plum'} bgOpacity="15" />
          )}
        </div>

        <div className={`flex ${disabled ? 'text-disabled-light-grey' : 'text-white'} text-opacity-48 my-4`}>
          <img src={numDays === 1 ? ClockIcon : CalendarIcon} alt="" className="mr-1" />
          <p className="uppercase">
            {props.start.format(DATE_FORMAT[0])} - {props.end.format(DATE_FORMAT[1])} {numDays === 1 ? 'MT' : ''}
          </p>
          <img src={LocationIcon} alt="" className="ml-8 mr-1" />
          <Link to={PRIMARY_ROUTES.contact.concat('/location')} className="underline">
            Catalyst Campus for Innovation
          </Link>
        </div>

        <p
          className={`${
            disabled ? 'cutoff-text-disabled text-disabled-light-grey' : 'cutoff-text-enabled text-white'
          } text-opacity-74 mb-4`}
          ref={descriptionRef as any}
        >
          {props.description}
        </p>
        <div className="flex justify-between">
          {props.children ? props.children : <div />}
          {willOverflow && (
            <input
              className={`expand-btn-h ${disabled ? BUTTON_STYLE_DISABLED : BUTTON_STYLE}`}
              type="checkbox"
              onClick={() => setExpanded(!expanded)}
              style={{ display: willOverflow ? 'block' : 'none' }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default HorizontalEventCard;
