import React, { FunctionComponent, ReactElement, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import CloseIcon from '../../assets/icons/Close.svg';
import './Dialog.css';

export interface DialogProps {
  show: boolean;
  handleClose: () => void;
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export type ButtonFocusRef = { current: any };

export const setBodyOverflowToAuto = () => {
  document.body.style.overflow = 'auto';
};

export const hideBodyOverflow = () => {
  document.body.style.overflow = 'hidden';
};

export const focusOnContinueButton = (ref: ButtonFocusRef) => {
  if (ref.current) ref.current.focus();
};

export const handleShowingPortal = (
  continueBtnRef: ButtonFocusRef,
  element: HTMLDivElement,
  modalRoot: HTMLElement | null
) => {
  if (modalRoot === null) return;
  modalRoot.appendChild(element);
  hideBodyOverflow();
  focusOnContinueButton(continueBtnRef);
  return () => {
    modalRoot.removeChild(element);
  };
};

const Dialog: FunctionComponent<DialogProps> = (props): ReactElement => {
  const continueBtnRef = useRef<HTMLButtonElement>(null);
  const element = document.createElement('div');

  useEffect(() => {
    return props.show
      ? handleShowingPortal(continueBtnRef, element, document.getElementById('modal-root'))
      : setBodyOverflowToAuto();
  }, [props.show, element]);

  return ReactDOM.createPortal(
    <div
      className={`dialogWrapper fixed inset-0 flex w-screen h-screen ${props.show ? '' : 'invisible'}`}
      onClick={props.handleClose}
    >
      <div
        className={`dialogBox mx-auto my-auto bg-dark-mirage border border-dark-dusty rounded-md z-20`}
        onClick={e => e.stopPropagation()}
      >
        <div className="flex flex-col">
          <img
            src={CloseIcon}
            alt=""
            id="closeDialogX"
            className="mt-2 ml-auto mr-2 cursor-pointer"
            onClick={props.handleClose}
          />
          {props.children}
        </div>
      </div>
    </div>,
    element
  );
};

export default Dialog;
