import React, { FunctionComponent, ReactElement } from 'react';
import { PortraitProps } from './TeamPortrait.props';

const TeamVerticalPortrait: FunctionComponent<PortraitProps> = (props): ReactElement => {
  return (
    <div className={`${props.containerClass}`}>
      <div style={{ width: '360px', height: '360px', background: `url(${props.img})`, backgroundSize: 'cover' }} />
      <div className="bg-dark-mirage p-5" style={{ height: '95px', width: '360px' }}>
        <p className="text-xl">{props.name.toUpperCase()}</p>
        <p className="text-sm">{props.title}</p>
      </div>
    </div>
  );
};

export default TeamVerticalPortrait;
