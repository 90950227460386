import React, { createRef, FunctionComponent, ReactElement, useEffect, useState } from 'react';
import ConnectWithUs from './ConnectWithUs/ConnectWithUs';
import LocateUs from './LocateUs/LocateUs';
import EventIcon from '../../assets/icons/Event.svg';
import WorkIcon from '../../assets/icons/Work.svg';
import NewsIcon from '../../assets/icons/News.svg';
import ReactGA from 'react-ga';
import './ContactUs.css';
import { generateScrollToRefListenerFunction, sleepThenScrollToRef } from '../../common/Helpers/ScrollHelpers';
import { useParams } from 'react-router-dom';
import { InitiativeRef } from '../InitiativesPage/InitiativesPage';

/* eslint-disable @typescript-eslint/no-explicit-any */

const ConnectWithUsIcon: FunctionComponent<{ subtitle: string; url: string }> = (props): ReactElement => {
  return (
    <div className={'flex flex-col items-center justify-center liIconDiv'} title={`Space CAMP ${props.subtitle}`}>
      <a
        onClick={() => {
          ReactGA.event({
            category: 'Marketing',
            action: `Clicked link to LinkedIn ${props.subtitle}.`,
            label: 'Linked In Icon'
          });
        }}
        target="__blank"
        href={props.url}
        className="connectWithUsLink"
      >
        {props.children}
        <p className="text-lg pt-4 ml-auto mr-auto w-full text-center">{props.subtitle}</p>
      </a>
    </div>
  );
};

const ContactUs: FunctionComponent = () => {
  const formRef = createRef<HTMLDivElement>();
  const { section } = useParams() as any;
  const [contactUsRefs] = useState<Map<string, InitiativeRef>>(new Map(['location'].map(i => [i, createRef()])));
  const [currentSection, setCurrentSection] = useState<string>();

  useEffect(() => {
    sleepThenScrollToRef(section, contactUsRefs, setCurrentSection);
  }, [section, contactUsRefs]);

  useEffect(() => {
    sleepThenScrollToRef(currentSection, contactUsRefs, setCurrentSection, 0);
  }, [currentSection, contactUsRefs]);

  useEffect(() => {
    const scrollToContactListener = generateScrollToRefListenerFunction(formRef, 50);
    document.addEventListener('scrollToContact', scrollToContactListener);
    return () => document.removeEventListener('scrollToContact', scrollToContactListener);
  }, [formRef]);

  return (
    <div ref={formRef} id="ContactUsPage" className="w-full flex flex-grow flex-col items-center py-20">
      <ConnectWithUs />
      <p
        className={`xl:text-4.5xl lg:text-4.5xl md:text-4xl sm:text-2xl xs:text-2xl font-oswald text-dark-mint px-4 pt-16 bg-dark-mirage w-full text-center`}
      >
        Connect with us on LinkedIn to find out more about:
      </p>
      <div className="w-full flex justify-around py-16 bg-dark-mirage text-white linkedInLinkBar">
        <ConnectWithUsIcon subtitle="Events" url="https://www.linkedin.com/company/spaceforce-spacecamp/events/">
          <img src={EventIcon} alt="EventsIcon" />
        </ConnectWithUsIcon>
        <ConnectWithUsIcon subtitle="News" url="https://www.linkedin.com/company/spaceforce-spacecamp/">
          <img src={NewsIcon} alt="NewsIcon" />
        </ConnectWithUsIcon>
        <ConnectWithUsIcon subtitle="Careers" url="https://www.linkedin.com/company/spaceforce-spacecamp/jobs/">
          <img src={WorkIcon} alt="WorkIcon" />
        </ConnectWithUsIcon>
      </div>
      <div className="w-full pb-16 bg-dark-mirage flex justify-center">
        <a
          onClick={() => {
            ReactGA.event({
              category: 'Marketing',
              action: `Clicked link to LinkedIn Connect with us.`,
              label: 'Linked In Icon'
            });
          }}
          href="https://www.linkedin.com/company/spaceforce-spacecamp"
          target="__blank__"
          className="connectWithUsLink border-2 border-dark-mint p-2 w-56 text-lg text-white text-center rounded-full hover:bg-dark-mint hover:text-black"
        >
          Yes, I want to Connect
        </a>
      </div>
      <LocateUs scrollRef={contactUsRefs.get('location')} />
    </div>
  );
};

export default ContactUs;
