import React, { FunctionComponent, useEffect, useState } from 'react';
import './ConnectWithUs.css';
import Dialog from '../../Dialog/Dialog';
import Zaephod from '../../../assets/images/Logos/Zaephod.svg';
import envVariables from '../../../common/Data/envVariables';
import ReactGA from 'react-ga';
const INPUT_STYLE = 'mt-5 border border-white p-2 text-base rounded-md bg-transparent ';
const OPTION_STYLE = 'bg-dark-mirage hover:bg-dark-dusty text-white border-dark-stone';

const interestDropdownOptions = [
  'Software / Application Development',
  'Research and Discovery',
  'Containerizing Existing Application',
  'Agile Leadership Training',
  'Digital Transformation',
  'Job / Internship opportunities',
  'Scheduling a Campus Visit'
];

const ConnectWithUs: FunctionComponent = () => {
  const [key, setKey] = useState(0);
  const [submitted, setSubmitted] = useState(false);

  const closeDialog = () => setSubmitted(false);

  useEffect(() => {
    submitted && setKey(k => k + 1);
  }, [submitted]);

  return (
    <div
      className={`upperSection bg-transparent flex xl:flex-row lg:flex-col md:flex-col sm:flex-col xs:flex-col justify-around items-center text-white w-full px-2 mt-20 mb-20 xl:mt-0`}
    >
      <iframe
        name="hidden_iframe"
        id="hidden_iframe"
        title="prevent google redirect"
        style={{ display: 'none' }}
      ></iframe>
      <Dialog show={submitted} handleClose={closeDialog}>
        <img src={Zaephod} alt="" className="mt-8 ml-auto mr-auto cursor-pointer" />
        <p className="mx-auto mt-8 lg:text-3xl md:text-3xl sm:text-xl xs:text-lg text-white">
          Thanks for reaching out!
        </p>
        <p className="mx-auto xl:text-md lg:text-md sm:text-base xs:text-base font-thin text-white text-center">
          Someone from our team will be in touch soon.
        </p>
        <button
          onClick={closeDialog}
          className="confirmDialog border-2 w-24 h-12 border-dark-mint p-2 mt-8 mx-auto text-white text-lg text-center bg-transparent rounded-full hover:bg-dark-mint hover:text-black"
        >
          OK!
        </button>
      </Dialog>

      <form
        key={key}
        className={`contactForm flex flex-col justify-center items-center py-10 connectWithUs border-dark-dusty rounded-lg xl:w-2/5 lg:w-full md:w-full sm:w-full xs:w-full`}
        action={envVariables.contactUrl}
        method="POST"
        target="hidden_iframe"
        onSubmit={() => {
          ReactGA.event({
            category: 'Partnership',
            action: 'Submitted Contact Information',
            label: 'Contact Form'
          });
          setSubmitted(true);
        }}
      >
        <div className={`flex flex-col w-4/5`}>
          <p className="text-3xl">Partner with Us</p>
          <input className={`${INPUT_STYLE}`} type="text" name="entry.992035606" placeholder="First Name*" required />
          <input className={`${INPUT_STYLE}`} type="text" name="entry.351302187" placeholder="Last Name*" required />
          <input className={`${INPUT_STYLE}`} type="email" name="entry.1461360255" placeholder="Email*" required />
          <input className={`${INPUT_STYLE}`} type="tel" name="entry.1029740306" placeholder="Phone Number" />
          <input className={`${INPUT_STYLE}`} type="text" name="entry.618489760" placeholder="Organization" />
          <select name="entry.50746799" className={`${INPUT_STYLE} pr-10`} required>
            <option className={`${OPTION_STYLE}`} value="">
              Im interested in*
            </option>
            {interestDropdownOptions.map(opt => {
              return (
                <option key={`${opt}-${key}`} className={`${OPTION_STYLE}`} value={opt}>
                  {opt}
                </option>
              );
            })}
          </select>

          <input className={`${INPUT_STYLE}`} type="text" name="entry.991710584" placeholder="How can we help?" />
        </div>
        <input
          type="submit"
          value="SUBMIT REQUEST"
          className="border-2 border-dark-mint p-2 mt-5 w-56 text-lg text-center bg-transparent rounded-full hover:bg-dark-mint hover:text-black cursor-pointer"
        />
      </form>
    </div>
  );
};

export default ConnectWithUs;
