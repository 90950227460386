import React, { FunctionComponent, ReactElement } from 'react';
// import SnapChatIcon from '../../assets/icons/SocialMediaIcons/SnapChat.svg';
// import FacebookIcon from '../../assets/icons/SocialMediaIcons/Facebook.svg';
import LinkedInIcon from '../../assets/icons/SocialMediaIcons/LinkedIn.svg';
import YouTubeIcon from '../../assets/icons/SocialMediaIcons/YouTube.svg';
import './SocialMediaBar.css';
import ReactGA from 'react-ga';

export interface SocialMediaBarProps {
  containerClass: string;
}

type SocialMediaClasses = {
  icon: string;
};

const useCommonClasses = (): SocialMediaClasses => ({
  icon: 'h-6 w-6'
});

const SocialMediaBar: FunctionComponent<SocialMediaBarProps> = (props): ReactElement => {
  const { icon } = useCommonClasses();
  return (
    <div className={`socialMediaWrapper flex flex-row justify-start ${props.containerClass}`}>
      {/* <a href="https://www.linkedin.com/company/air-force-spacecamp" target="__blank__">
        <img src={SnapChatIcon} alt="SnapChat" className={`snapChatIcon`} />
      </a> */}
      <a
        className="linkedInLink"
        onClick={() => {
          ReactGA.event({
            category: 'Marketing',
            action: 'Clicked link to LinkedIn.',
            label: 'Linked In Icon'
          });
        }}
        rel="noreferrer noopener"
        href="https://www.linkedin.com/company/air-force-spacecamp"
        target="__blank__"
      >
        <img src={LinkedInIcon} alt="LinkedInIcon" className={`linkedInIcon ${icon}`} />
      </a>
      <a
        className="youTubeLink"
        onClick={() => {
          ReactGA.event({
            category: 'Marketing',
            action: 'Clicked link to YouTube.',
            label: 'YouTube Icon'
          });
        }}
        rel="noreferrer noopener"
        href="https://www.youtube.com/channel/UC60kixuYO9qbcXn86VwTr4w"
        target="__blank__"
      >
        <img src={YouTubeIcon} alt="YouTubeIcon" className={`youTubeIcon h-8 w-8`} />
      </a>

      {/* <a href="https://www.linkedin.com/company/air-force-spacecamp" target="__blank__">
        <img src={FacebookIcon} alt="Facebook" className={`facebookIcon`} />
      </a> */}
    </div>
  );
};

export default SocialMediaBar;
