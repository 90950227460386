import React, { FunctionComponent, ReactElement, useRef, useState } from 'react';
import { TagEnum } from '../../../common/enums/Tag.enum';
import { EventCardProps, ENABLED_DATEBOX, DISABLED_DATEBOX } from './HorizontalEventCard';
import Tag from './Tag/Tag';
import ClockIcon from '../../../assets/icons/ClockIcon.svg';
import CalendarIcon from '../../../assets/icons/CalendarIcon.svg';
import LocationIcon from '../../../assets/icons/LocationIcon.svg';
import { Link } from 'react-router-dom';
import { PRIMARY_ROUTES } from '../../../common/Routes/PrimaryRoutes';
import { useWillOverflow } from '../../../common/CustomHooks/UseWillOverflow';
import './EventCard.css';
import './VerticalEventCard.css';
/* eslint-disable @typescript-eslint/no-explicit-any */

const BUTTON_STYLE = 'text-dark-mint uppercase';
const BUTTON_STYLE_DISABLED = 'text-disabled-light-grey uppercase';

const VerticalEventCard: FunctionComponent<EventCardProps> = (props): ReactElement => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const descriptionRef = useRef();
  const willOverflow = useWillOverflow(descriptionRef);

  const { disabled } = props;

  const eventCardBackground = disabled ? 'bg-disabled-dark-grey' : 'bg-dark-deepSea';
  const dateBox = disabled ? DISABLED_DATEBOX : ENABLED_DATEBOX;

  const numDays = props.end.diff(props.start, 'day') + 1;
  const ONE_DAY_FORMAT = ['HHmm', 'HHmm'];
  const MULTI_DAY_FORMAT = ['MMM D', 'D, YYYY'];

  let DATE_FORMAT = [];
  if (numDays === 1) DATE_FORMAT = ONE_DAY_FORMAT;
  else DATE_FORMAT = MULTI_DAY_FORMAT;

  return (
    <div
      className={`vertical-event-card-main ${props.classString} block text-white ${eventCardBackground} p-4 md:mt-6 sm:mt-4 xs:mt-4`}
    >
      <div className="flex">
        <div className={`flex flex-col items-center ${dateBox.text}`}>
          <div className={`vertical-event-card-date-wrapper flex justify-center ${dateBox.border}`}>
            <div className={`flex justify-center ${dateBox.background} self-center`}>
              <p className="self-center text-2xl">{props.start.format('D')}</p>
            </div>
          </div>
          <p className="text-sm mt-1 uppercase">{props.start.format('MMM')}</p>
        </div>

        <p className={`${expanded ? '' : 'truncate'} text-xl font-oswald uppercase ml-6`}>{props.eventName}</p>
      </div>
      <div className="flex justify-start mt-4">
        {numDays > 1 && numDays <= 99 && (
          <Tag text={`${numDays} Days`} bgColor={disabled ? 'disabled-grey' : 'dark-teal'} bgOpacity="12" />
        )}
        {props.tags.includes(TagEnum.IN_PERSON) && (
          <Tag text={TagEnum.IN_PERSON} bgColor={disabled ? 'disabled-grey' : 'dark-lightGray'} bgOpacity="20" />
        )}
        {props.tags.includes(TagEnum.ONLINE) && (
          <Tag text={TagEnum.ONLINE} bgColor={disabled ? 'disabled-grey' : 'dark-plum'} bgOpacity="15" />
        )}
      </div>
      <div
        className={`flex ${
          disabled ? 'text-disabled-light-grey' : 'text-white'
        } text-opacity-48 text-sm leading-4 mt-4 mb-2`}
      >
        <img src={numDays === 1 ? ClockIcon : CalendarIcon} alt="" className="mr-1" />
        <p className="uppercase">
          {props.start.format(DATE_FORMAT[0])} - {props.end.format(DATE_FORMAT[1])} {numDays === 1 ? 'MT' : ''}
        </p>
      </div>
      <div className="flex text-white text-opacity-48 text-sm leading-4 mb-4">
        <img src={LocationIcon} alt="" className="mr-1" />
        <Link to={PRIMARY_ROUTES.contact.concat('/location')} className="underline">
          Catalyst Campus for Innovation
        </Link>
      </div>
      <p
        className={`text-sm leading-4 ${
          disabled ? 'cutoff-text-disabled text-disabled-light-grey' : 'cutoff-text-enabled text-white'
        } text-opacity-74 mb-6`}
        ref={descriptionRef as any}
      >
        {props.description}
      </p>
      <div className="flex justify-between">
        {props.children ? props.children : <div />}
        {willOverflow && (
          <input
            className={`expand-btn-v ${disabled ? BUTTON_STYLE_DISABLED : BUTTON_STYLE}`}
            type="checkbox"
            onClick={() => setExpanded(!expanded)}
            style={{ display: willOverflow ? 'block' : 'none' }}
          />
        )}
      </div>
    </div>
  );
};

export default VerticalEventCard;
