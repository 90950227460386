import React, { FunctionComponent, ReactElement } from 'react';
import GalleryPic1 from '../../../assets/images/Events/GalleryPic1.png';
import GalleryPic2 from '../../../assets/images/Events/GalleryPic2.png';
import GalleryPic3 from '../../../assets/images/Events/GalleryPic3.png';
import GalleryPic4 from '../../../assets/images/Events/GalleryPic4.png';
import GalleryPic5 from '../../../assets/images/Events/GalleryPic5.png';
import GalleryPic6 from '../../../assets/images/Events/GalleryPic6.png';
import GalleryPic7 from '../../../assets/images/Events/GalleryPic7.png';

const PhotoGallery: FunctionComponent = (): ReactElement => {
  return (
    <div className="photoGallery">
      <div className="flex">
        <div>
          <img src={GalleryPic1} alt="Gallery" />
        </div>
        <div>
          <img src={GalleryPic2} alt="Gallery" />
        </div>
        <div className="xl:block lg:block md:hidden sm:hidden xs:hidden">
          <img src={GalleryPic3} alt="Gallery" />
        </div>
      </div>
      <div className="xl:hidden lg:hidden md:flex sm:flex xs:flex">
        <div>
          <img src={GalleryPic3} alt="Gallery" />
        </div>
        <div>
          <img src={GalleryPic4} alt="Gallery" />
        </div>
      </div>
      <div className="flex">
        <div className="xl:block lg:block md:hidden sm:hidden xs:hidden">
          <img src={GalleryPic4} alt="Gallery" />
        </div>
        <div>
          <img src={GalleryPic5} alt="Gallery" />
        </div>
        <div>
          <img src={GalleryPic6} alt="Gallery" />
        </div>
        <div>
          <img src={GalleryPic7} alt="Gallery" />
        </div>
      </div>
    </div>
  );
};

export default PhotoGallery;
