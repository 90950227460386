import React, { FunctionComponent, ReactElement } from 'react';
import BannerImage from '../../assets/images/ProcessBanner.png';
import Hero from '../Hero/Hero';
import { ourDifferences } from '../../common/Data/ProcessPageResources';
import Claim from '../Claim/Claim';
import periodicTable from '../../assets/images/PeriodicTable.png';
import ProcessPartnership from '../../assets/images/ProcessPartner.png';
import { PADDING_Y } from '../AscensionDay/AscensionDay';

const ProcessPage: FunctionComponent = (): ReactElement => {
  return (
    <div>
      <Hero image={BannerImage} title="Our Process" classString="sm:bg-process xs:bg-top xs:bg-xsProcess" />
      <Claim
        claim="A Culture of Innovation"
        description="Our mission is to pioneer a collaborative ecosystem to accelerate dynamic, digital solutions for the US Space Force from discovery through deployment."
      />
      <div
        className={`${PADDING_Y} flex xl:flex-row lg:flex-row md:flex-col sm:flex-col xs:flex-col justify-evenly items-center xl:px-0 lg:px-0 md:px-8 sm:px-8 xs:px-8`}
      >
        <div className="text-white w-full xl:w-5/12 lg:w-5/12">
          <p className="xl:text-5xl lg:text-3xl md:text-3xl sm:text-xl xs:text-xl xl:text-left lg:text-left md:text-center sm:text-center xs:text-center font-oswald">
            Collaboration Drives Our Success
          </p>
          <p className="xl:text-2xl lg:text-lg md:text-base sm:text-base xs:text-base text-left mt-8">
            We don’t just build software that space operators need, we build the software that operators want. At Space
            CAMP, our products are developed through close collaboration with the actual people who will be using our
            software everyday.
          </p>
          <p className="xl:text-2xl lg:text-lg md:text-base sm:text-base xs:text-base text-left mt-8">
            We embrace Agile software development, meaning we partner alongside both stakeholder and end users
            throughout development, inviting them to brainstorm, ideate, and validate the product each step of the way.
            This close collaboration ensures that the product we deliver meets the operators biggest needs while
            remaining flexible enough to grow and pivot as the mission evolves.
          </p>
        </div>
        <img
          src={ProcessPartnership}
          alt="Residency Program"
          className="xl:w-2/5 lg:w-2/5 md:w-5/6 sm:w-5/6 xs:w-full xl:mt-0 lg:mt-0 md:mt-8 sm:mt-8 xs:mt-8"
        />
      </div>
      <div
        className={`w-full bg-dark-mirage flex xl:flex-row lg:flex-row md:flex-col sm:flex-col xs:flex-col justify-evenly flex-wrap px-4 ${PADDING_Y}`}
      >
        <p className="xl:w-4/5 lg:w-full md:w-full sm:w-full xs:w-full xl:text-5xl lg:text-4xl md:text-4xl sm:text-3xl xs:text-3xl font-oswald text-dark-mint text-center pb-4 px-4">
          What Makes Us Different
        </p>
        {ourDifferences.map((difference, index) => {
          return (
            <div
              className={`text-white flex flex-col  xl:w-1/2 lg:w-1/2 md:w-full sm:w-full xs:w-full px-4 pt-8`}
              key={`${difference.title}:${index}`}
            >
              <p className="xl:text-3xl lg:text-3xl md:text-2xl sm:text-2xl xs:text-2xl xl:w-3/5 lg:w-3/5 md:w-full sm:w-full xs:w-full ml-auto mr-auto font-light py-2">
                {difference.title}
              </p>
              <p className="text-lg xl:w-3/5 lg:w-3/5 md:w-full sm:w-full xs:w-full ml-auto mr-auto font-light">
                {difference.description}
              </p>
            </div>
          );
        })}
      </div>
      <div className={`w-full flex flex-col items-center ${PADDING_Y}`}>
        <Claim
          claim="Our Innovation Toolkit"
          description="At Space CAMP, we work closely with our clients to evaluate the needs of each project and create a custom development journey using the Agile tools & practices listed below."
          wrapperClass="w-full pb-16"
        />
        <img src={periodicTable} alt="periodic table of innovation" className="w-5/6" />
      </div>
    </div>
  );
};

export default ProcessPage;
