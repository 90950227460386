import React, { FunctionComponent, ReactElement } from 'react';

export interface ScheduleEvent {
  title: string;
  subtitle: string;
}

export interface ScheduleProps {
  leftColumnHeader: ScheduleEvent;
  leftColumnEvents: ScheduleEvent[];
  rightColumnHeader: ScheduleEvent;
  rightColumnEvents: ScheduleEvent[];
}

const EVENT_TITLE = 'xl:text-3xl lg:text-3xl md:text-2xl sm:text-xl xs:text-xl text-dark-mint';
const EVENT_SUBTITLE = 'font-light xl:text-lg lg:text-lg md:text-lg sm:text-md xs:text-md';
const EVENT_WRAPPER = 'my-4';
const COLUMN_TITLE = 'xl:text-4.25xl lg:text-4.25xl md:text-3xl sm:text-3xl xs:text-3xl';
const COLUMN_SUBTITLE = '';
const Schedule: FunctionComponent<ScheduleProps> = (props): ReactElement => {
  return (
    <div className="flex flex-col items-center w-full my-2">
      <div className="w-full mt-4 px-4 flex xl:flex-row xl:justify-around lg:flex-row lg:justify-around md:flex-col sm:flex-col xs:flex-col">
        <div>
          <div className={COLUMN_TITLE}>{props.leftColumnHeader.title}</div>
          <div className={COLUMN_SUBTITLE}>{props.leftColumnHeader.subtitle}</div>
          {props.leftColumnEvents.map((e: ScheduleEvent, index: number) => (
            <div className={EVENT_WRAPPER} key={`${e.subtitle}:${index}`}>
              <p className={EVENT_TITLE}>{e.title}</p>
              <p className={EVENT_SUBTITLE}>{e.subtitle}</p>
            </div>
          ))}
        </div>
        <div className="xl:mt-0 lg:mt-0 md:mt-8 sm:mt-8 xs:mt-8">
          <div className={COLUMN_TITLE}>{props.rightColumnHeader.title}</div>
          <div className={COLUMN_SUBTITLE}>{props.rightColumnHeader.subtitle}</div>
          {props.rightColumnEvents.map((e: ScheduleEvent, index: number) => (
            <div className={EVENT_WRAPPER} key={`${e.subtitle}:${index}`}>
              <p className={EVENT_TITLE}>{e.title}</p>
              <p className={EVENT_SUBTITLE}>{e.subtitle}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Schedule;
