import React, { FunctionComponent, ReactElement } from 'react';
import SpaceCampSocialFlyer from '../../assets/images/SpaceCampSocial/SpaceCampSocialFlyer.png';

const PADDING_X = 'xl:px-40 lg:px-8 md:px-8 sm:px-2 xs:px-2';
export const PADDING_Y = 'xl:py-20 lg:py-20 md:py-20 sm:py-8 xs:pt-16 xs:pb-8';

const AscensionDay: FunctionComponent = (): ReactElement => {
  return (
    <div className="spaceCampSocial">
      <div className={`flex flex-col items-center ${PADDING_X} ${PADDING_Y}`}>
        <img src={SpaceCampSocialFlyer} alt="Space Camp Social Flyer" />
      </div>
    </div>
  );
};

export default AscensionDay;
