import { z } from 'zod';
/* eslint-disable @typescript-eslint/camelcase */

export enum TagEnum {
  IN_PERSON = 'In Person',
  ONLINE = 'Online'
}

export const TagsEnum = z.nativeEnum(TagEnum, {
  invalid_type_error: 'Tag: Value must be "2 Days", "In Person" or "Online"'
});
export type TagsEnum = z.infer<typeof TagsEnum>;
