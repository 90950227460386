import React, { FunctionComponent, ReactElement } from 'react';
import { ArrowProps } from './ArrowProps';

const RightArrow: FunctionComponent<ArrowProps> = (props): ReactElement => {
  return (
    <div onClick={props.onClick} title="Move right." className={props.className}>
      {props.children}
    </div>
  );
};

export default RightArrow;
