/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const envSettings = window as any;
const envVariables = {
  trackingId: envSettings._env_?.TRACKING_ID || 'foo',
  develop: JSON.parse(envSettings._env_?.DEVELOP || 'false'),
  contactUrl: envSettings._env_?.CONTACT_URL || '/',
  BIZOPS_EVENTS_SHEET_ID: envSettings._env_?.BIZOPS_EVENTS_SHEET_ID || 'false',
  DRAGON_ARMY_EVENTS_SHEET_ID: envSettings._env_?.DRAGON_ARMY_EVENTS_SHEET_ID || 'false'
};

export default envVariables;
