import React, { FunctionComponent, ReactElement } from 'react';
import { ArrowProps } from './ArrowProps';

const LeftArrow: FunctionComponent<ArrowProps> = (props): ReactElement => {
  return (
    <div onClick={props.onClick} title="Move left." className={props.className}>
      {props.children}
    </div>
  );
};

export default LeftArrow;
