import React, { ReactElement, FunctionComponent, useState, RefObject, createRef, useEffect } from 'react';
import BannerImage from '../../assets/images/WorkBanner.png';
import Hero from '../Hero/Hero';
import ProductCard from '../ImageDescriptionCards/ProductCard';
import { MissionCriticalApplications, SpaceApplications } from '../../common/Data/WorkPageResources';
import Claim from '../Claim/Claim';
import { useParams } from 'react-router-dom';
import { sleepThenScrollToRef } from '../../common/Helpers/ScrollHelpers';
import { hasNext, getNext, getProjectCardBackground } from './WorkPageHelpers';

export type ProjectRef = RefObject<HTMLDivElement> | null;
export interface WorkPageParams {
  name: string;
}

const WorkPage: FunctionComponent = (): ReactElement => {
  const { name } = useParams() as WorkPageParams;
  const [currentProject, setCurrentProject] = useState<string>();
  const [projectRefs] = useState<Map<string, ProjectRef>>(
    new Map([...SpaceApplications, ...MissionCriticalApplications].map(p => [p.title, createRef()]))
  );

  useEffect(() => {
    sleepThenScrollToRef(name, projectRefs, setCurrentProject);
  }, [name, projectRefs]);

  useEffect(() => {
    sleepThenScrollToRef(currentProject, projectRefs, setCurrentProject, 0);
  }, [currentProject, projectRefs]);

  return (
    <div className={`workPageWrapper`}>
      <Hero image={BannerImage} title="Our Work" classString="bg-work" />
      <Claim
        claim="Space Domain Applications"
        description="Our teams specialize in the development of space domain applications that empower space operators with the tools and information they need to execute their mission."
      />
      <div className={`flex flex-row justify-evenly flex-wrap`}>
        {SpaceApplications.map((project, projectIndex) => (
          <ProductCard
            showArrow={hasNext(MissionCriticalApplications, projectIndex) || MissionCriticalApplications.length > 0}
            arrowClick={() => {
              if (hasNext(SpaceApplications, projectIndex)) setCurrentProject(getNext(SpaceApplications, projectIndex));
              else setCurrentProject(MissionCriticalApplications[0].title);
            }}
            cardRef={projectRefs.get(project.title)}
            key={`${project.title}:${projectIndex}`}
            {...project}
            wrapperClass={getProjectCardBackground(projectIndex)}
          />
        ))}
      </div>
      <Claim
        claim="Mission Critical Applications"
        description="We develop mobile and web applications for the Air Force and DoD in mission critical areas, including medical records, pandemic response, and cloud platform."
      />
      <div className={`flex flex-row justify-evenly flex-wrap`}>
        {MissionCriticalApplications.map((project, projectIndex) => (
          <ProductCard
            showArrow={hasNext(MissionCriticalApplications, projectIndex)}
            arrowClick={() =>
              hasNext(MissionCriticalApplications, projectIndex) &&
              setCurrentProject(getNext(MissionCriticalApplications, projectIndex))
            }
            cardRef={projectRefs.get(project.title)}
            key={`${project.title}:${projectIndex}`}
            {...project}
            wrapperClass={getProjectCardBackground(projectIndex)}
          />
        ))}
      </div>
    </div>
  );
};

export default WorkPage;
