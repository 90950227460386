import React, { useEffect } from 'react';
import { EventsContext, EventsContextInterface } from '../Providers/EventsProvider';
import PublicGoogleSheetsParser from 'public-google-sheets-parser';
import envVariables from '../Data/envVariables';
import { ZodIssue } from 'zod/lib/ZodError';
import { SafeParseError } from 'zod';
import { eventTransformer, ValidEvent, ZodEvent } from '../Interfaces/EventInfo';
import moment from 'moment';
import { DefaultEvents } from '../Data/Events';
/* eslint-disable @typescript-eslint/no-explicit-any */

const BIZOPS_EVENTS_SHEET_ID: string = envVariables.BIZOPS_EVENTS_SHEET_ID;
const DRAGON_ARMY_EVENTS_SHEET_ID: string = envVariables.DRAGON_ARMY_EVENTS_SHEET_ID;

const loadWithDefaultsIfNoEvents = (events: ValidEvent[]): void => {
  if (events.length === 0) {
    console.warn(`Parsed events but found 0 viable results, inserting ${DefaultEvents.length} default events`);
    events.push(...DefaultEvents);
  }
};

const formatIssues = (eventName: string, issues: ZodIssue[]): string => {
  const tabulatedErrors = issues.reduce((acc, issue) => {
    return acc + '\t' + issue.message + '\n';
  }, '');

  return eventName + '\n' + tabulatedErrors;
};

const gracefullyConsoleWarnIssues = (
  spreadsheetName: 'Bizops' | 'Dragon Army',
  eventNames: string[],
  errors: SafeParseError<ZodEvent>[]
) => {
  try {
    errors.forEach((error, index) => {
      const uniqueRowId = eventNames[index] ? `"${eventNames[index]}" ` : '';
      const header = `The event ${uniqueRowId}at row ${index + 1} contains errors and will not be displayed`;
      console.warn(`${spreadsheetName} spreadsheet contains errors...\n`, formatIssues(header, error.error.issues));
    });
  } catch {
    console.warn('Unknown error, contact dev team');
  }
};

const gracefullyHandleParsing = (
  response: any[]
): { events: ValidEvent[]; errorEventNames: string[]; errors: SafeParseError<ZodEvent>[] } => {
  try {
    const errorEventNames: string[] = []; // Because we lose the event data when it fails to parse
    const events: ValidEvent[] = [];
    const errors: SafeParseError<ZodEvent>[] = [];
    response
      .filter(event => event['Event Name'] !== 'Placeholder') // For some reason it doesn't parse correctly unless the first row of the sheet has all fields populated. The Placeholder event is there to fulfill this need. Filter is to remove the Placeholder row from the pool of events.
      .forEach(event => {
        const result = eventTransformer.safeParse(event);

        result.success && events.push(result.data);
        !result.success && errorEventNames.push(event['Event Name']) && errors.push(result);
      });
    return { events, errorEventNames, errors };
  } catch {
    return { events: [], errorEventNames: [], errors: [] };
  }
};

const sortEvents = (events: ValidEvent[]) => {
  events.sort((a, b) => b.start.diff(a.start));
  const today = moment();
  const futureEvents = events.filter(event => today.isBefore(event.start));
  const pastEvents = events.filter(event => today.isAfter(event.start)); // Once again tried to use reduce for this but was causing weird runtime errors

  return { pastEvents, futureEvents };
};

export const useEvents = (): EventsContextInterface => {
  const eventsContext = React.useContext(EventsContext);

  useEffect(() => {
    if (eventsContext.state === 'new') {
      Promise.all([
        new PublicGoogleSheetsParser(BIZOPS_EVENTS_SHEET_ID, 'Events').parse(),
        new PublicGoogleSheetsParser(DRAGON_ARMY_EVENTS_SHEET_ID, 'Events').parse()
      ]).then(response => {
        const {
          events: bizopsEvents,
          errorEventNames: bizopsErrorrEventNames,
          errors: bizopsErrors
        } = gracefullyHandleParsing(response[0]);
        const {
          events: dragonArmyEvents,
          errorEventNames: dragonArmyErrorrEventNames,
          errors: dragonArmyErrors
        } = gracefullyHandleParsing(response[1]);

        gracefullyConsoleWarnIssues('Bizops', bizopsErrorrEventNames, bizopsErrors);
        gracefullyConsoleWarnIssues('Dragon Army', dragonArmyErrorrEventNames, dragonArmyErrors);

        const allEvents = bizopsEvents;
        loadWithDefaultsIfNoEvents(allEvents);
        allEvents.push(...dragonArmyEvents);

        const { pastEvents, futureEvents } = sortEvents(allEvents);

        eventsContext.setEvents(pastEvents, futureEvents);
      });
    }
  }, [eventsContext]);

  return eventsContext;
};
