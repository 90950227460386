import React, { FunctionComponent, ReactElement } from 'react';
import { PortraitProps } from './TeamPortrait.props';

const TeamHorizontalPortrait: FunctionComponent<PortraitProps> = (props): ReactElement => {
  return (
    <div className={`${props.containerClass} flex`} style={{ width: '344px', height: '118px' }}>
      <div style={{ width: '118px', height: '118px', background: `url(${props.img})`, backgroundSize: 'cover' }} />
      <div className="bg-dark-mirage p-6" style={{ height: '118px', width: '226px' }}>
        <p className="text-base">{props.name.toUpperCase()}</p>
        <p className="text-xs">{props.title}</p>
      </div>
    </div>
  );
};

export default TeamHorizontalPortrait;
