import React, { FunctionComponent, ReactElement, useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { PRIMARY_ROUTES } from '../../../common/Routes/PrimaryRoutes';
import SpaceCampLogoAndTitle from './../../../assets/images/Logos/SpaceCampLogoWithTitle-wide.svg';

import NavTab from '../NavTab/NavTab';
import './NavBarCollapse.css';

const NAV_TAB_STYLE = 'flex flex-col w-4/5 text-center ml-auto mr-auto mb-5 collapsed';

const NavBarCollapsed: FunctionComponent = (): ReactElement => {
  const activeTab = useLocation().pathname;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [transitionToX, setTransitionToX] = useState('');

  const transitionX = useCallback(() => {
    if (transitionToX === '') setTransitionToX('transition');
    else setTransitionToX('');
  }, [transitionToX, setTransitionToX]);

  useEffect(() => {
    if (drawerOpen) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'auto';
  }, [drawerOpen]);

  return (
    <>
      <div className="nbCollapsedWrapper flex flex-row justify-between items-center bg-dark-mirage text-white pr-6 py-2 madShadow h-20">
        <div>
          <Link to={PRIMARY_ROUTES.home} className="flex flex-row justify-between" title="To home page.">
            <img
              src={SpaceCampLogoAndTitle}
              alt="Space Camp Logo and Title"
              className="md:w-80 sm:w-64 xs:w-48 md:ml-4 sm:ml-4 xs:ml-2 "
            />
          </Link>
        </div>
        <div
          className="openCollapsedDrawerBtn"
          onClick={() => {
            transitionX();
            setDrawerOpen(!drawerOpen);
          }}
        >
          <div className={`barContainer ${transitionToX}`}>
            <div className="bar1 bar"></div>
            <div className="bar2 bar"></div>
            <div className="bar3 bar"></div>
          </div>
        </div>
      </div>
      <div
        className={`nbCollapsedDrawerWrapper  ${drawerOpen ? 'notHidden' : 'transitionToHidden'}`}
        onClick={() => {
          transitionX();
          setDrawerOpen(!drawerOpen);
        }}
      >
        <div
          className={`nbCollapsedDrawer absolute top-1 right-0 w-3/5 bg-dark-stone text-white pt-16 madShadow ${
            drawerOpen ? 'translate-x-0' : 'translate-x-full'
          }`}
          onClick={e => e.stopPropagation()}
        >
          <div className="flex flex-col justify-around">
            <NavTab tabName="HOME" className={NAV_TAB_STYLE} tabLink={PRIMARY_ROUTES.home} />
            <NavTab tabName="INITIATIVES" className={NAV_TAB_STYLE} tabLink={PRIMARY_ROUTES.initiatives} />
            <NavTab tabName="PROCESS" className={NAV_TAB_STYLE} tabLink={PRIMARY_ROUTES.process} />
            <NavTab tabName="WORK" className={NAV_TAB_STYLE} tabLink={PRIMARY_ROUTES.work} />
            <NavTab tabName="EVENTS" className={NAV_TAB_STYLE} tabLink={PRIMARY_ROUTES.events} />
            <NavTab tabName="CAREERS" className={NAV_TAB_STYLE} tabLink={PRIMARY_ROUTES.careers} />
            <NavTab tabName="TEAM" className={NAV_TAB_STYLE} tabLink={PRIMARY_ROUTES.team} />

            <Link
              className={`shadow-md border-2 border-dark-mint text-center rounded-full xl:text-xl lg:text-xl md:text-xl sm:text-md xs:text-xs xl:px-6 lg:px-6 md:px-6 sm:px-4 xs:px-2 py-2 xl:ml-6 xl:mt-0 lg:mt-0 md:mt-2 sm:mt-2 xs:mt-2 font-light w-4/5 ml-auto mr-auto ${
                activeTab.includes(PRIMARY_ROUTES.contact) ? 'bg-dark-mint' : 'text-white hover:bg-dark-mint'
              }`}
              to={PRIMARY_ROUTES.contact}
              title="To contact us page."
            >
              CONTACT US
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavBarCollapsed;
