import React, { ReactElement, FunctionComponent } from 'react';
import DownArrow from '../../assets/icons/DownArrow.svg';
import { PADDING_Y } from '../AscensionDay/AscensionDay';
import './ProductCard.css';

export interface ProductCardProps {
  title: string;
  description: string | string[];
  image: string;
  wrapperClass?: string;
  cardRef?: React.LegacyRef<HTMLDivElement>;
  showArrow?: boolean;
  arrowClick?: () => void;
}

const ProductCard: FunctionComponent<ProductCardProps> = (props): ReactElement => {
  return (
    <div
      ref={props.cardRef}
      className={`projectCard w-full flex flex-col justify-around ${props.wrapperClass} ${PADDING_Y}`}
    >
      <div className="flex xl:flex-row lg:flex-row md:flex-col sm:flex-col xs:flex-col justify-around items-center">
        <div className="h-full xl:w-2/3 lg:w-2/3 md:w-full sm:w-full xs:w-full xl:mb-8 lg:m-0 md:m-0 sm:m-0 xs:m-0 flex justify-around items-center">
          {props.image.startsWith('http') ? (
            <div className="resp-container w-3/4">
              <iframe
                className="resp-iframe"
                src={props.image}
                frameBorder="0"
                title={props.title}
                scrolling="no"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          ) : (
            <img src={props.image} alt={`${props.title} LOGO`} className="w-3/4" />
          )}
        </div>
        <div className="text-white xl:w-1/3 lg:w-1/3 md:w-full sm:w-full xs:w-full xl:p-0 xl:mb-8 lg:p-0 lg:mb-8 md:px-8 sm:px-8 xs:px-8 pt-8 xl:justify-around">
          <p className="xl:text-5xl lg:text-4xl md:text-4xl sm:text-2xl xs:text-2xl xl:m-0 lg:m-0 md:pb-4 sm:pb-4 xs:pb-4 font-oswald">
            {props.title}
          </p>
          <div className="xl:text-xl lg:text-xl md:text-xl sm:text-base xs:text-base text-left xl:mt-16 xl:mr-16 lg:mt-8 lg:mr-8">
            {!Array.isArray(props.description) && props.description}
            {Array.isArray(props.description) &&
              props.description.map((description, descriptionIndex) => {
                return (
                  <div key={`${props.title}:${descriptionIndex}`}>
                    <p>{description}</p>
                    <br />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <div
        onClick={props.arrowClick as React.MouseEventHandler<HTMLDivElement>}
        title="Move to next."
        className={`${
          !props.showArrow ? 'hidden' : 'xl:block lg:block hidden'
        } nextCardArrow cursor-pointer ml-auto mr-auto rounded-full pb-4 pt-6 h-20 w-20 flex items-center justify-center`}
      >
        <img src={DownArrow} alt="DownArrowIcon" />
      </div>
    </div>
  );
};

export default ProductCard;
